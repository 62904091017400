

#princig_model_config table {
    border: 1px solid #CCCCCC;
    border-width: 0px 1px 1px 0px;
}
#princig_model_config th {
    background-color: #EEEEEE;
    border: 1px solid #CCCCCC;
    border-width: 1px 0px 0px 1px;
}


#princig_model_config thead th:hover {
    text-decoration: underline;
    cursor: pointer;
}

#princig_model_config thead th.is-selected-0 {
    background-color: green;
}

#princig_model_config thead th.is-selected-1,
#princig_model_config thead th.is-selected-star {
    background-color: blue;
}

#princig_model_config td {
    background-color: white;
    border: 1px solid #CCCCCC;
    border-width: 1px 0px 0px 1px;
    width: 100px;
    height: 20px;
    position: relative;
}
#princig_model_config td,
.pricing-element-editor-mini *{
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 18px;
}
#princig_model_config td input{
    font-size: 14px;
}

#princig_model_config td .invalid *{
    background-color: orange;
}

#princig_model_config th{
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
}

.pricing-element-editor-mini{
    padding: 0;
    margin: 0;
    border: 0;
}
.pricing-element-editor-mini input,
.pricing-element-editor-mini button{
    height: 100%;
    line-height: 19px;
    height: 19px;
    width:100%;
    margin: 0;
    border: 0;
    padding:0;
    margin-right: -20px;
    display: inline;
}

.pricing-element-editor-mini .expand{
    width: 20px;
}

.pricing-element-editor-mini.expanded,
.pricing-element-editor-mini.expanded input{
    background-color: #777777;
}
.pricing-element-editor-mini.expanded{
    opacity: .5;
}

.pricing-element-editor-mini input:focus { outline: none; }

.pricing-element-editor-mini.not-defined input{
    color: #777777;
}

.pricing-element-editor-mini.not-defined input:focus {
    background-color: red;
}

.pricing-element-editor {
    background-color: #FFFFFF;
    border:1px solid #CCCCCC;
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    width: 300px;
    box-shadow: #00000066 2px 2px;
}
.pricing-element-editor .close{
    display: block;
    position: absolute;
    top:0;
    right:0;
}
.pricing-element-editor *[readonly]{
    color:gray;
}


.is-default {
    color:#777777;
}

.is-dirty {
    color: black;
    background-color: #FFFFE0;
}
.is-dirty:after {
    content: " ";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-top: 8px solid #FF0000;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}

.hidden {
    display:none;
}